import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BlogWrapper from "../../../components/article/BlogWrapper";
import StateBusinessBankBlogContent from "../../../components/state-business-bank/StateBusinessBankAccountRequirementsBlogContent";
import { Link } from "gatsby";

// NOTE: for apostrophes or single quotes in the title to render, please use an ASCII single quote and wrap the string in backticks.
const SEO = {
  MetaTitle: `What's Needed to Open a Business Bank Account in Oregon`,
  MetaDescription:
    "Learn what's needed to open a business bank account in Oregon including key documents needed. Open an Oregon business bank account today with WaFd Bank.",
  Schema: {
    "@id": "https://www.wafdbank.com/blog/small-business/oregon-business-bank-account-requirements",
    "@type": "Article",
    image: "https://www.wafdbank.com/images/adsearch/blogs/og-buisness-account-oregon-082724.jpg",
    "@context": "https://schema.org",
    headline: "What's Needed to Open a Business Bank Account in Oregon",
    description:
      "Learn what's needed to open a business bank account in Oregon including key documents needed. Open an Oregon business bank account today with WaFd Bank."
  }
};

const BeforeStartingOwnBusinessBlog = () => {
  const cards = [
    "/blog/small-business/choosing-location-for-business",
    "/blog/small-business/small-business-cybersecurity-tips",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ];

  const considerContent = (
    <p>
      Oregon boasts nearly 400,000 small businesses employing more than 900,000 Oregonians. In fact, companies under 500
      employees make up 99.5% of Oregon businesses. WaFd Bank is here to help business continue to grow and boost the
      local economy, such as creating jobs. WaFd Bank is a regional bank providing{" "}
      <Link to="/business-banking/small-business" id="blog-small-businesses-link">
        business banking solutions
      </Link>{" "}
      so you can easily manage finances and focus on growing your business. A variety of services and tools we offer can
      help you get your business off the ground (or to the next level) including:
    </p>
  );

  const stateData = {
    introText:
      "Oregon is the ninth largest state in the United States by geographic size, and business is booming in the Beaver state. If you've started your own business or are thinking about it, know that it's important to keep your own business funds separate from your personal finances. It helps you stay organized and focus on your business priorities, and as an added bonus, it helps to protect your personal assets, too. So, what exactly do you need to know? Read on!",
    image: (
      <StaticImage
        src="../../../images/blogs/small-business/state-business-bank-account-requirements/thumbnail-business-account-oregon-082724.jpg"
        alt="Flying view of Oregon."
        placeholder="blurred"
        quality="100"
      />
    ),
    considerContent
  };

  return (
    <BlogWrapper
      cards={cards}
      title={"What Is Needed to Open a Business Bank Account in Oregon"}
      pageUrl={"/blog/small-business/oregon-business-bank-account-requirements"}
      ogImage={"https://www.wafdbank.com/images/adsearch/blogs/og-buisness-account-oregon-082724.jpg"}
      contactInfo={"LocalBranch"}
      seoData={SEO}
    >
      <StateBusinessBankBlogContent {...stateData} />
    </BlogWrapper>
  );
};

export default BeforeStartingOwnBusinessBlog;
